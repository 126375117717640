<template>
    <div class="booking-background">
        <div class="header-booking">
            <h1 class="header-font">Booking</h1>
        </div>
        <div class="content-booking">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateDisplay"
                    label="Picker without buttons"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="form.selectDate"
                    :width="datePickerWidth"
                    @input="dateMenu = false"
                    :min="datePickerOption.min"
                    :max="datePickerOption.max"
                ></v-date-picker>
            </v-menu>
            <div v-if="epClassList.length > 0" class="booking-button mb-4">
                <div v-if="isUpdateBooking">
                    <v-btn class="mr-2" color="primary create-btn" outlined @click="cancel()">Cancel</v-btn>
                    <v-btn color="primary" @click="submit">Save</v-btn>
                </div>
                <div v-else>
                    <v-btn color="primary create-btn" @click="isUpdateBooking = true">Click to Booking</v-btn>
                </div>
            </div>
            <div v-else>
                Not found class on this day
            </div>
            <div>
                <v-card class="card-ep-class" v-for="epClass, index in epClassList" :key="epClass.id">
                    <div>Class: {{epClass.name}}</div>
                    <div>Date: {{epClass.date}}</div>
                    <div>Time: {{epClass.time}}</div>
                    <div>Branch: {{epClass.branchName}}</div>
                    <div v-if="!isUpdateBooking">
                        <div>
                            Status: <span  :class="[epClass.isBooking ? 'booked-text' : 'not-booked-text']">{{epClass.isBooking ? "BOOKED" : "NOT BOOKED"}}</span>
                        </div>
                    </div>
                    <div class="booking-status-btn" v-else>
                        <v-btn v-if="epClass.isBooking" color="error" @click="epClass.isBooking = false">Remove</v-btn>
                        <v-btn v-else color="success" @click="addBookingClass(epClass, index)">Add</v-btn>
                    </div>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import liff from '@line/liff'
export default {
    name: 'Booking',
    data () {
        return {
            form: {
                selectDate: ''
            },
            isUpdateBooking: false,
            dateMenu: false,
            epClassList: [
            ],
            datePickerOption: {
                min: '',
                max: ''
            },
            liffAccessToken: '',
            datePickerWidth: '290'
        }
    },
    computed: {
        dateDisplay () {
            return this.formatDate(this.form.selectDate)
        }
    },

    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        async getDateOptions () {
            const res = await axios.get(`${process.env.VUE_APP_BACKEND}/line/get-date-options`)
            if (res && res.data && res.data.status === 'ok') {
                this.datePickerOption = {
                    min: res.data.data.min,
                    max: res.data.data.max
                }
                this.form.selectDate = res.data.data.min
            }
        },

        async getEpClassList () {
            const res = await axios.get(`${process.env.VUE_APP_BACKEND}/line/ep-classes?selectDate=${this.form.selectDate}&liffAccessToken=${this.liffAccessToken}`)
            if (res && res.data && res.data.status === 'ok') {
                this.epClassList = res.data.data
            }
        },

        addBookingClass (selectClass, index) {
            let isOverlap = false
            const [selectedStart, selectedEnd] = selectClass.time.split(' - ')
            const overlapClass = []
            for (let i = 0; i < this.epClassList.length; i++) {
                const epClass = this.epClassList[i]
                const [epClassStart, epClassEnd] = epClass.time.split(' - ')
                if (epClass.isBooking) {
                    if (epClassStart === selectedStart && epClassEnd === selectedEnd) {
                        isOverlap = true
                        overlapClass.push(epClass.name)
                    } else if (epClassStart < selectedStart && selectedStart < epClassEnd) {
                        isOverlap = true
                        overlapClass.push(epClass.name)
                    } else if (epClassStart < selectedEnd && selectedEnd < epClassEnd) {
                        isOverlap = true
                        overlapClass.push(epClass.name)
                    } else if (selectedStart < epClassStart && epClassEnd < selectedEnd) {
                        isOverlap = true
                        overlapClass.push(epClass.name)
                    }
                }
            }

            if (isOverlap) {
                this.$notify({
                    title: 'Error',
                    text: `This class is overlap with ${overlapClass.toString()}`,
                    type: 'error'
                })
            } else {
                this.epClassList[index].isBooking = true
            }
        },

        async submit () {
            const res = await axios.post(`${process.env.VUE_APP_BACKEND}/line/ep-classes`, {
                selectDate: this.form.selectDate,
                classList: this.epClassList,
                liffAccessToken: this.liffAccessToken
            })
            if (res && res.data && res.data.status === 'ok') {
                await this.getEpClassList()
                this.isUpdateBooking = false
            }
        },

        async cancel () {
            await this.getEpClassList()
            this.isUpdateBooking = false
        }
    },

    async mounted () {
        if (window.innerWidth < 350) {
            this.datePickerWidth = 250
        }
        await liff.init({ liffId: process.env.VUE_APP_LIFF_BOOKING }).catch(err => { throw err })
        this.liffAccessToken = liff.getAccessToken()
        await this.getDateOptions()
    },

    watch: {
        async 'form.selectDate' (newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.getEpClassList()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.booking-background {
    width: 100vw;
    height: 100vh;
    background-color: #502B94;

    .header-booking {
        padding-top: 12px;
        text-align: center;
        height: 10%;
    }

    .content-booking {
        padding: 3rem 1.5rem;
        height: 90%;
        background-color: #fff;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        overflow: auto;
        .booking-button {
            display: flex;
            justify-content: flex-end;
        }
    }

    .card-ep-class {
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 20px;

        .booking-status-btn {
            display: flex;
            justify-content: flex-end;
        }
    }

    .booked-text {
        color: #4caf50;
    }

    .not-booked-text {
        color: #fb8c00;
    }

    .header-font {
        font-size: 3rem;
        color: #ffffff;
    }
}
</style>
